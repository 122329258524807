.App {
  text-align: center;
}

/*110124 start: to enable to see video play bar, so it will not be blocked by footer */
html, body {
  height: 100%;
  margin: 0;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.app-content {
  flex: 1;
}

/*1101/24 end */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-without-bg {
  color: white;
}

.text-with-bg {
  background-color: SkyBlue;
  display: inline;
  color: white;
}

.text-centered-with-bg {
  background-color: SkyBlue;
  display: inline;
  color: white;
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f0f0f0; /* Optional background color */
  padding: 10px; /* Optional padding for better visibility */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*FC add the following two on 05/29/24 for loading chat video spinner used in personToTalkForm.jsx*/
.spinner {
  border: 16px solid rgba(255, 255, 255, 0.2); /* Light transparent white */
  border-top: 16px solid white; /* Solid white */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



